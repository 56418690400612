<template>
    <div class="page">
        <HeadContent>
            <div slot="bgImg" style="">
                <img src="../../assets/common/topTitleBg.png" style="margin-top: 70px;">
            </div>
            <div slot="title" style="width: 100%">{{ $route.query.title }}</div>
        </HeadContent>
        <div class="arcsnav">
            <div class="pointer" @click="goRoute('home')">首页</div>
            <i class="el-icon-arrow-right"></i>
            <div class="pointer" @click="goRoute('informationCenter')">资讯中心</div>
            <i class="el-icon-arrow-right"></i>
            <div>{{ $route.query.title }}</div>
            <div class="shu"></div>
            <div class="time" v-if="article">{{ formattedCreateDate(item.createDate) }}</div>
        </div>
        <div class="news-bbb-box d_jump">
            <div class="container">
                <div class="news">
                    <div class="news-cbox" v-if="qyxwList.length>0">
                        <ul>
                            <li class="new-li" v-for="(item,index) in qyxwList" :key="index">
                                <div class="new-col" @click="goMore(item.id,'企业新闻')">
                                    <div class="news-t">{{item.title}}</div>
                                    <div class="news-date">{{formattedCreateDate(item.createDate)}}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="news-cbox" v-else style="color:#666;padding-top: 20px;">
                        暂无数据
                    </div>
                    <div class="btn-box">
                        <el-pagination
                                background
                                layout="prev, pager, next"
                                :current-page="pageInfo.pageNum"
                                :page-size="pageInfo.pageSize"
                                :total="pageInfo.total"
                                @current-change="pageChange"
                                @prev-click="pageChange"
                                @next-click="pageChange"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import newsApi from "@/api/news.js"

    export default {
        data() {
            return {
                activeName: 'one',
                activeinfo: [
                    {label: '企业新闻', name: 'one'},
                    {label: '法律法规', name: 'two'},
                    {label: '行业动态', name: 'five'}
                ],
                qyxwList: [],
                flfgList: [],
                hydtList: [],
                article: null,
                pageInfo: {
                    page: 0,
                    pageSize: 15,
                    pageNum: 1,
                    total: 0
                }
            }
        },
        watch: {
            $route: {
                handler(newName, oldName) {
                    //执行代码
                    let index = newName.query.index
                    if (typeof  this.activeinfo[index - 1] != "undefined") {
                        this.activeName = this.activeinfo[index - 1].name
                        this.handleClick({
                            index: index - 1
                        })
                    }

                },
                deep: true
            }
        },
        mounted() {
            this.getData(1)
            if (typeof this.activeinfo[this.$route.query.index] != "undefined") {
                this.activeName = this.activeinfo[this.$route.query.index].name
            }
        },
        methods: {
            handleClick(tab, event) {
                const jump = document.querySelectorAll('.d_jump')
                const total = jump[tab.index].offsetTop
                let distance = (document.documentElement.scrollTop || document.body.scrollTop)
                // 平滑滚动，时长500ms，每10ms一跳，共50跳
                let step = total / 50
                if (total > distance) {
                    smoothDown()
                } else {
                    const newTotal = distance - total
                    step = newTotal / 50
                    smoothUp()
                }

                function smoothDown() {
                    if (distance < total) {
                        distance += step
                        document.body.scrollTop = distance
                        document.documentElement.scrollTop = distance
                        setTimeout(smoothDown, 10)
                    } else {
                        document.body.scrollTop = total
                        document.documentElement.scrollTop = total
                    }
                }

                function smoothUp() {
                    if (distance > total) {
                        distance -= step
                        document.body.scrollTop = distance
                        document.documentElement.scrollTop = distance
                        setTimeout(smoothUp, 10)
                    } else {
                        document.body.scrollTop = total
                        document.documentElement.scrollTop = total
                    }
                }
            },
            goMore(id, title) {
                this.$router.push({
                    name: "article",
                    query: {id, title}
                })
            },
            goRoute(name, index) {
                console.log(11)
                this.$router.push({
                    name, index
                })
            },
            pageChange(num) {
                this.pageInfo.pageNum = num
                this.getData(this.pageInfo.pageNum)
            },
            getData(pageNum) {
                let name = this.$route.query.title
                let size = this.pageInfo.pageSize
                if (name == "企业新闻") {
                    newsApi.listNews(1, pageNum, size).then((response) => {
                        this.qyxwList = response.rows
                        this.pageInfo.total = response.total
                    })
                } else if (name == "法律法规") {
                    newsApi.listNews(2, pageNum, size).then((response) => {
                        this.qyxwList = response.rows
                        this.pageInfo.total = response.total
                    })
                } else {
                    newsApi.listNews(3, pageNum, size).then((response) => {
                        this.qyxwList = response.rows
                        this.pageInfo.total = response.total
                    })
                }
            },
            formattedCreateDate(time) {
                const date = new Date(time)
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            }
        }
    }
</script>

<style scoped lang="scss">
    $TabHeadHeight: 60px;
    ::v-deep .el-tabs__arcsnav-scroll {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        max-width: 1200px;
        height: $TabHeadHeight;
        background-color: #f5f5f5;
        margin: 0 auto;

        .el-tabs__arcsnav {
            width: 100%;
        }

    }

    ::v-deep .el-tabs__arcsnav-wrap::after {
        display: none;
    }

    ::v-deep .el-tabs__header {
        margin-bottom: 0;
    }

    ::v-deep .el-tabs__item {
        height: $TabHeadHeight;
        line-height: 60px;
    }

    .news {
        .news-cbox {

            .new-li {
                padding: 20px 40px 20px 10px;
                &:hover {
                    cursor: pointer;
                    color: #1320E8;
                }
            }

            .new-col {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .news-t {
                    max-width: 700px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .btn-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .news-more-btn {
            margin-top: 30px;
            color: #4954F7;
            border: 1px solid #465AEE;
            padding: 6px 14px;
            font-size: 12px;
            letter-spacing: 2px;

        }

        .news-more-btn:hover {
            cursor: pointer;
            color: #fff;
            background-color: #1320E8;
            border-color: #1320E8;
        }
    }

    .news-bbb-box {
        width: 100%;
        padding: 10px 0 40px;
    }

    .flfg {
        background-color: #fbfbfb;
    }

    .container {
        margin-bottom: 0;
    }

    .arcsnav {
        width: 1200px;
        margin: 0 auto;
        color: #707070;
        display: flex;
        align-items: center;
        padding: 40px 80px 0px;

        div {
            &:hover {
                cursor: pointer;
            }
            &:nth-child(5) {
                cursor: default;
            }
        }

        i {
            margin: 0 4px;
        }

        .shu {
            width: 1px;
            height: 20px;
        }

        .time {
            position: relative;
            margin-top: 2px;
            margin-left: 40px;
            display: flex;
            align-items: center;
            &:after {
                content: "";
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 12px;
                background: #707070;
            }
        }
    }

    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #1320e8;
        color: #FFF !important;
    }

    ::v-deep .number {
        &:hover {
            color: #1320e8 !important;
        }
    }
</style>

import request from '@/axios/index.js'

export default {
    // 查询新闻列表
    listNews(type, pageNum, pageSize) {
        return request({
            url: '/front/api/newList',
            method: 'get',
            params: {
                type,
                pageNum,
                pageSize: pageSize || 15
            }
        })
    },
    //根据id查询文章
    newDetail(id) {
        return request({
            url: '/front/api/newDetail',
            method: 'get',
            params: {
                id
            }
        })
    },
    homeNews() {
        return request({
            url: '/front/api/indeArticleList?pageNum=1&pageSize=3',
            method: 'get'
        })
    },
    homeInfo(id) {
        return request({
            url: '/front/api/homeArtById?id=' + id,
            method: 'get'
        })
    }
}
